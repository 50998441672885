.fs-fitness-pros {
    background-color: #F7F7F7;
}

.fs-fitness-pros .slick-slide {
    text-align: center;
}

.fs-fitness-pros-slide .ant-image-img {
    width: 235px;
    height: 235px;
    border-radius: 250px;
    margin: 0 auto;
    object-fit: cover;
    border: var(--white) 10px solid;
    outline: rgba(0, 0, 0, .06) 8px solid;
    transition: all .3s;
}

.fs-fitness-pros-slide:hover .ant-image-img {
    outline: var(--primary) 8px solid;
}

.fs-fitness-pros-slide h4.ant-typography {
    text-align: center;
    text-transform: uppercase;
    margin-top: 25px;
}

.fs-fitness-pros .slick-list {
    padding-top: 12px;
}

.fs-fitness-pros .slick-prev,
.fs-fitness-pros .slick-next {
    top: 43%
}

@media(max-width: 767px) {
    .fs-fitness-pros-slide .ant-image-img {
        width: 200px;
        height: 200px;
    }
}