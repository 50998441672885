.fs-portfolio-card.fs-add-portfolio {
    padding-left: 0;
    padding-right: 0;
}

.fs-portfolio-outer textarea.ant-input {
    height: 150px;
    margin-top: 30px;
}

@media(max-width: 1191px) {}

@media(max-width: 991px) {}

@media(max-width: 767px) {}

@media(max-width: 767px) {}