.fs-sponsors-slide {
    border: var(--border) 1px solid;
    border-radius: var(--br-12);
    overflow: hidden;
    position: relative;
    height: 120px;
    display: flex!important;
    align-items: center;
    justify-content: center;
}

.fs-sponsors-slide .ant-image-img {
    height: 80px;
    object-fit: contain;
}
