.fs-ambassadors {
    background-color: #F7F7F7;
}

.fs-ambassadors-slider {
    position: relative;
}

.fs-ambassadors-slider .ant-image-img {
    border-radius: var(--br-20);
    height: 430px;
    object-fit: cover;
}

.fs-ambassadors-slider h4.ant-typography {
    margin-top: 15px;
    text-align: center;
}

.fs-social-links {
    position: absolute;
    top: 50%;
    right: -25px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    width: 52px;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.05);
}

.fs-social-links li:not(:first-child) {
    margin-top: 5px;
}

.fs-social-links li a {
    width: 42px;
    height: 42px;
    line-height: 42px;
    border-radius: 8px;
    background-color: #EFEFEF;
    display: block;
    position: relative;
}

.fs-social-links li a svg {
    color: var(--text);
}

.fs-social-links li a:hover {
    background-color: var(--primary);
}

.fs-social-links li a:hover svg {
    color: var(--white);
}

@media(max-width: 1191px) {
    .fs-ambassadors-slider .ant-image-img {
        height: 350px;
    }
}

@media(max-width: 991px) {
    .fs-ambassadors-slider .ant-image-img {
        height: 325px;
    }
}

@media(max-width: 576px) {
    .fs-ambassadors-slider h4.ant-typography {
        font-size: 15px;
    }

    .fs-ambassadors-slider .ant-image-img {
        height: 350px;
    }

    .fs-social-links {
        right: 15px;
    }
}