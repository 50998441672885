.fs-auth-img .ant-image,
.fs-auth-logo .ant-image,
.fs-auth-img .ant-image-img {
    width: 100%;
}

.fs-auth-img .ant-image-img {
    height: calc(100vh - 0px);
    object-fit: cover;
}

.fs-auth-content-col {
    height: 100vh;
    overflow-y: auto;
}

.fs-auth-content {
    max-width: 560px;
    width: 100%;
    margin: 0 auto;
    padding: 30px 20px;
}

.fs-auth-logo .ant-image-img {
    height: 33px;
    margin: 0 auto;
    object-fit: contain;
}
.ant-form-item-with-help .ant-form-item-explain{
    margin-top: 5px ;
    margin-bottom: 20px;
}

.fs-auth-card {
    border: var(--border) 1px solid;
    padding: 40px;
    border-radius: var(--br-12);
    margin-top: 35px;
}

.fs-auth-card-header {
    text-align: center;
}

.fs-auth-card-header-title {
    text-transform: uppercase;
}

.ant-form.fs-auth-form {
    margin-top: 50px;
}

.fs-auth-card .ant-btn {
    width: 100%;
}

.fs-auth-card .ant-row:last-child {
    margin-bottom: 0;
}

.fs-forgot-pass {
    margin-bottom: 24px;
    display: inline-block;
    display: flex;
    justify-content: flex-end;
}

.fs-auth-form .ant-checkbox-wrapper .ant-checkbox {
    position: relative;
    top: 8px;
    margin-right: 5px;
}

@media(max-width: 991px) {
    .ant-row.fs-field-mrgn {
        margin-top: 24px;
    }
}

@media(max-width: 767px) {
    .fs-auth-img .ant-image-img {
        height: 400px;
    }

    .fs-auth-card .ant-col {
        width: 100%;
    }

    .fs-auth-content-col {
        height: auto;
    }

    .fs-auth-content {
        max-width: 100%;
    }

    .ant-row.fs-field-mrgn {
        margin-top: 0;
    }
}

@media(max-width: 576px) {
    .fs-img-col.ant-col {
        display: none;
    }

    .fs-auth-logo .ant-image-img {
        height: 22px;
    }

    .fs-auth-card {
        width: 100%;
        padding: 20px;
        margin-top: 30px;
    }

    .fs-auth-content {
        width: initial;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .ant-form.fs-auth-form {
        margin-top: 30px;
    }

    .ant-row.fs-field-mrgn {
        margin-top: 24px;
    }
}