.fs-portfolio-card {
    padding-left: 70px;
    padding-right: 70px;
}

.fs-portfolio-images {
    display: flex;
    gap: 30px;
}

.fs-portfolio-images .ant-image {
    overflow: hidden;
    border-radius: var(--br-12);
}

.fs-portfolio-images img {
    height: 700px;
    object-fit: cover;
}

.fs-portfolio-outer {
    position: relative;
}

.fs-portfolio-outer textarea.ant-input {
    height: 150px;
    margin-top: 30px;
}

.fs-portfolio-footer .ant-typography {
    line-height: 1.5;
}

@media(max-width: 1191px) {
    .fs-portfolio-images img {
        height: 600px;
    }
}

@media(max-width: 991px) {
    .fs-portfolio-images img {
        height: 500px;
    }

    .fs-float-btn .fs-absolute-btn {
        transform: translateY(0%);
    }
}

@media(max-width: 767px) {
    .fs-portfolio-card.fs-mt-40 {
        padding-left: 0;
        padding-right: 0;
        margin-top: 30px;
    }

    .fs-portfolio-images {
        grid-gap: 20px;
        gap: 20px;
    }

    .fs-portfolio-images img {
        height: 400px;
    }
    
    .fs-portfolio-outer textarea.ant-input {
        height: 100px;
    }
}

@media(max-width: 767px) {
    .fs-portfolio-images {
        grid-gap: 15px;
        gap: 15px;
    }

    .fs-portfolio-images img {
        height: 260px;
    }
}