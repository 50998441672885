.fd-tab .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #333333;
}

.fd-tab .ant-tabs-nav-wrap {
    background-color: #ff1a1a;
    border-radius: inherit;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #333333;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.3;
    list-style: none;
    font-feature-settings: 'tnum'
}

.fd-tab .ant-tabs-tab {
    border: 1px solid !important;
}

.ant-tabs-tab:hover{
    color: white !important;
}

