.fs-blog-posts {
    margin-bottom: 60px;
}

@media(max-width: 991px) {
    .fs-blog-posts {
        margin-bottom: 0;
    }
}

.fs-spin {
    height: calc(100vh - 505px);
    display: flex;
    align-items: center;
    justify-content: center;
}