.fs-contact h1.ant-typography {
    color: var(--primary);
    font-weight: 600;
    text-transform: uppercase;
}

.fs-contact-content {
    padding-left: 10%;
}

.fs-contactus-form {
    margin-top: 30px !important;
}

.fs-contactus-form textarea.ant-input {
    height: 150px;
}

@media(max-width: 767px) {
    .fs-contact.fs-section {
        padding-bottom: 0;
    }
    .fs-contact-content {
        padding-left: 0;
        margin-top: 50px;
    }

    .fs-contact h1.ant-typography {
        font-size: 28px;
    }

    .fs-span {
        display: none;
    }
}