.fs-hero {
    position: relative;
    height: calc(100vh - 111px);
    /* background: url('../../../../assets/images/hero.jpg') no-repeat top center; */
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fs-hero:after {
    content: '';
    background-color: rgba(0, 0, 0, .2);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.fs-hero .ant-image {
    width: 100%;
}

.fs-hero-content {
    position: relative;
    z-index: 1;
}

.fs-hero-content h1.ant-typography {
    font-size: 48px;
    text-transform: uppercase;
    color: var(--white);
    margin: 0;
    text-align: center;
    line-height: 1.4;
}

.fs-hero-content h1.ant-typography span {
    background: url('../../../../assets/images/hero-vector.svg') no-repeat top center;
    background-size: contain;
    width: 430px;
    height: auto;
    display: inline-block;
    background-position: 36px -1px;
}

.fs-hero-para.ant-typography {
    font-size: 16px;
    color: var(--white);
    text-align: center;
    margin: 20px auto 30px;
    max-width: 60%;
}

@media(min-width: 1440px) {
    .fs-hero-content h1.ant-typography {
        font-size: 60px;
    }

    .fs-hero-content h1.ant-typography span {
        width: 490px;
        height: 79px;
        background-position: 26px 2px;
    }

    .fs-hero-para.ant-typography {
        max-width: 50%;
    }
}

@media(max-width: 1191px) {
    .fs-hero-content h1.ant-typography {
        font-size: 36px;
    }

    .fs-hero-content h1.ant-typography span {
        width: 405px;
        background-position: 72px -1px;
    }
}

@media(max-width: 991px) {
    .fs-hero {
        height: 600px;
    }

    .fs-hero-content h1.ant-typography {
        font-size: 36px;
    }

    .fs-hero-content h1.ant-typography span {
        width: 405px;
        background-position: 72px -1px;
    }
}

@media(max-width: 767px) {
    .fs-hero {
        height: 400px;
    }

    .fs-hero-content h1.ant-typography {
        font-size: 26px;
    }

    .fs-hero-content h1.ant-typography span {
        width: 345px;
    }
}

@media(max-width: 576px) {
    .fs-hero {
        height: 320px;
    }

    .fs-hero-content h1.ant-typography {
        font-size: 20px;
    }

    .fs-hero-content h1.ant-typography span {
        width: 290px;
    }

    .fs-hero-para.ant-typography {
        font-size: 14px;
        max-width: 90%;
    }
}

.banner .slick-dots{
    bottom : 0 !important
}

.banner .slick-prev {
    left: 3% !important;
    z-index: 1;
  }
  .banner .slick-next {
    right: 3% !important;
    z-index: 1;
  }