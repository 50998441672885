.fs-blog-slide {
    text-align: center;
    position: relative;
}

.fs-blog-slide a {
    display: block;
}

.fs-blog-slide .ant-image,
.fs-blog-slide img {
    width: 100%;
}

.fs-blog-slide img {
    height: calc(100vh - 111px);
    object-fit: cover;
}

.fs-blog-slide h1.ant-typography {
    font-size: 48px;
    text-transform: uppercase;
    color: var(--white);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100px;
    width: 60%;
}

.fs-blog-slider .slick-dots {
    bottom: 50px;
}

.fs-blog-slider .slick-dots li,
.fs-blog-slider .slick-dots li button,
.fs-blog-slider .slick-dots li button:before {
    width: 30px;
    height: 6px;
}

.fs-blog-slider .slick-dots li button {
    background: var(--white);
    opacity: 1;
    padding: 0;
}

.fs-blog-slider .slick-dots li button:before {
    content: '';
    opacity: 0;
}

.fs-blog-slider .slick-dots li.slick-active button {
    background-color: var(--primary);
}

@media(min-width: 1440px) {
    .fs-blog-slide h1.ant-typography {
        font-size: 60px;
    }
}

@media(max-width: 1191px) {
    .fs-blog-slide h1.ant-typography {
        font-size: 36px;
    }
}

@media(max-width: 991px) {
    .fs-blog-slide img {
        height: 450px;
    }

    .fs-blog-slide h1.ant-typography {
        font-size: 28px;
    }
}

@media(max-width: 767px) {
    .fs-blog-slide img {
        height: 400px;
    }

    .fs-blog-slide h1.ant-typography {
        font-size: 28px;
    }

    .fs-blog-slider .slick-dots {
        bottom: 58px;
    }
}

@media(max-width: 576px) {
    .fs-blog-slide img {
        height: 300px;
    }

    .fs-blog-slide h1.ant-typography {
        font-size: 22px;
        width: 90%;
        bottom: 70px;
    }

    .fs-blog-slider .slick-dots {
        bottom: 38px !important;
    }

    .fs-blog-slider .slick-dots li,
    .fs-blog-slider .slick-dots li button,
    .fs-blog-slider .slick-dots li button:before {
        height: 3px;
    }
}