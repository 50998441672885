.fs-about-content {
    padding-left: 60px;
}

.fs-about-content h2.ant-typography,
.fs-about-content .ant-typography+h1.ant-typography {
    margin: 0;
    text-transform: uppercase;
}

.fs-about-content h2.ant-typography {
    font-size: 36px;
}

.fs-about-content h1.ant-typography {
    font-size: 58px;
    color: var(--primary);
    font-weight: 600;
}

.fs-about-para {
    line-height: 1.5;
    margin-top: 20px;
    display: block;
}

.fs-about-ul {
    margin-top: 40px;
}

.fs-about-ul li {
    position: relative;
    padding-left: 35px;
    margin-top: 20px;
}

.fs-about-ul li:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: var(--white);
    border: var(--primary) 4px solid;
    width: 25px;
    height: 25px;
    border-radius: 30px;
}

.fs-about-content .ant-btn {
    margin-top: 40px;
}

@media(min-width: 1440px) {
    .fs-about-content h2.ant-typography {
        font-size: 40px;
    }

    .fs-about-content h1.ant-typography {
        font-size: 66px;
    }
}

@media(max-width: 991px) {
    .fs-about-content h2.ant-typography {
        font-size: 30px;
    }

    .fs-about-content h1.ant-typography {
        font-size: 48px;
    }

    .fs-about-ul,
    .fs-about-content .ant-btn {
        margin-top: 30px;
    }
}

@media(max-width: 767px) {
    .fs-about-content {
        margin-top: 40px;
        padding-left: 0;
    }
}

@media(max-width: 576px) {
    .fs-about-content h2.ant-typography {
        font-size: 22px;
        margin-bottom: 5px;
    }

    .fs-about-content h1.ant-typography {
        font-size: 36px;
    }

    .fs-about-ul li {
        padding-left: 30px;
    }

    .fs-about-ul li:before {
        border: var(--primary) 3px solid;
        width: 20px;
        height: 20px;
        top: 47%;
    }
}