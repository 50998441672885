.fs-ad-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
}
.modelAddScroll{
    overflow-y: scroll;
    height: 550px;
}

.fs-ad-footer h4.ant-typography {
    margin-bottom: 0;
    padding-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 75%;
}

.fs-ad-price {
    color: var(--primary);
    font-size: 18px;
    font-weight: 600;
}

.fs-ad-values {
    margin-bottom: 8px;
}

.fs-ad-values,
.fs-ad-values ul {
    display: flex;
}

.fs-ad-values span {
    font-weight: 500;
}

.fs-ad-values ul {
    flex-wrap: wrap;
}

.fs-ad-values ul li {
    padding-left: 6px;
    padding-right: 4px;
    position: relative;
}

.fs-ad-values ul li:after {
    content: ',';
    position: absolute;
    right: 0;
    bottom: 0;
}

.fs-ad-values ul li:last-child {
    padding-right: 0;
}

.fs-ad-values ul li:last-child:after {
    display: none;
}

.fs-ad-values span strong {
    font-weight: 500;
}

.fs-ad-two {
    justify-content: space-between;
}

.fs-ad-two span {
    font-weight: normal;
}

.fs-ad-div {
    display: block;
}

.fs-ad-div span {
    display: block;
    margin-bottom: 2px;
}