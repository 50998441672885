.fs-page-header {
    background-color: #F7F7F7;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.fs-block-header h3.ant-typography {
    font-size: 26px;
    text-transform: uppercase;
    color: var(--primary);
    margin-top: 0;
    margin-bottom: 0;
}

@media(min-width: 1440px) {
    .fs-page-header {
        height: 300px;
    }
}

@media(max-width: 767px) {
    .fs-page-header {
        height: 200px;
    }

    .fs-block-header h3.ant-typography {
        font-size: 20px;
    }
}

.ant-rate-star-second {
    color: #98989894 !important
}