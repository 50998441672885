.fs-topbar {
    background-color: var(--white);
    padding: 9px 0;
    border-bottom: var(--border) 1px solid;
}

.fs-topbar-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fs-topbar-contact,
.fs-topbar-contact li a,
.fs-social-media,
.fs-auth-nav,
.fs-auth-nav li {
    display: flex;
    align-items: center;
}

.fs-topbar-contact li:first-child {
    margin-right: 50px;
}

.fs-topbar-contact li a {
    color: var(--text);
}

.fs-topbar-contact li a svg {
    fill: var(--text);
    margin-right: 8px;
}

.fs-topbar-contact li a span {
    letter-spacing: 1px;
    position: relative;
    top: 1px;
}

.fs-social-media li:not(:last-child) {
    margin-right: 10px;
}

.fs-social-media li a {
    background-color: var(--text);
    color: var(--white);
    width: 22px;
    height: 22px;
    line-height: 25px;
    display: block;
    text-align: center;
    border-radius: 5px;
}

.fs-social-media li a:hover {
    background-color: var(--primary);
}

.fs-header {
    position: relative;
}

.fs-header.ant-layout-header {
    border-bottom: var(--border) 1px solid;
    line-height: initial;
    padding-left: 0;
    padding-right: 0;
    height: 70px;
    line-height: initial;
    position: sticky;
    top: 0;
    z-index: 2;
}

.fs-header .fs-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
}

.fs-hamburger {
    display: flex;
    align-items: center;
    padding: 5px;
    margin-left: -10px;
    display: none;
    position: absolute;
    top: 50%;
    left: 18px;
    transform: translateY(-50%);
}

.fs-side-drawer {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 3;
    background-color: var(--white);
    width: 250px;
    height: 100vh;
    padding: 20px 20px;
    transform: translateX(-100%);
    transition: all .3s;
}

.fs-logo .ant-image-img {
    height: 26px;
    position: relative;
    top: -2px;
}

.fs-logo a {
    display: inline-block;
}

.fs-header .ant-menu-vertical {
    display: flex;
    align-items: center;
    border: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.fs-header .ant-menu-vertical .ant-menu-item {
    margin-top: 0;
    margin-bottom: 0 !important;
    text-transform: uppercase;
    padding-top: 1px;
}

.fs-header .ant-menu-vertical .ant-menu-item:not(:last-child) {
    margin-right: 10px;
    border-radius: 10px;
}

.fs-auth-nav li {
    text-transform: uppercase;
}

.fs-auth-nav li:first-child a:hover {
    color: var(--primary);
}

.fs-auth-nav li a {
    color: var(--text);
}

.fs-auth-nav .ant-btn {
    height: 40px;
    line-height: 40px;
    border-radius: var(--br-6);
    color: var(--white);
    margin-left: 25px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 10px;
}

.fs-auth-menu.ant-btn {
    border: none;
    height: initial;
    line-height: initial;
    padding: 0;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    padding-top: 0px !important;
}

.fs-auth-menu .ant-avatar .ant-image-img {
    width: 35px;
    height: 35px;
    object-fit: cover;
    object-position: top center;
}

.fs-auth-name {
    margin-left: 10px;
    margin-right: 5px;
}

.ant-dropdown-menu {
    overflow: hidden;
    padding: 0;
}

.fs-footer.ant-layout-footer {
    padding: 0;
    margin-top: 60px;
}

.fs-footer-top {
    background-color: var(--text);
    padding: 60px 0;
}

.fs-footer-top .fs-my-account-contact-list li {
    color: var(--white);
}

.fs-footer-top .fs-my-account-contact-list li span {
    position: relative;
    top: 1px;
}

.fs-footer-top .fs-my-account-contact-list li svg {
    fill: var(--white);
}

.fs-footer-top .fs-social-media li a {
    background-color: rgba(255, 255, 255, .12);
    width: 30px;
    height: 30px;
    line-height: 38px;
}

.fs-footer-top .fs-social-media li a:hover {
    background-color: var(--primary);
}

.fs-footer-top h4.ant-typography {
    text-transform: uppercase;
    color: var(--white);
}

.fs-footer-links {
    margin-top: 30px;
}

.fs-footer-links li {
    margin-top: 15px
}

.fs-footer-links li a {
    color: var(--white);
}

.fs-footer-links li a:hover {
    color: var(--primary);
}

.fs-footer-copyright {
    background-color: #2B2A2A;
    color: var(--white);
    text-align: center;
    padding: 25px 20px;
}

@media(max-width: 1100px) {
    .fs-header .ant-menu {
        display: none;
    }

    .fs-logo {
        padding-left: 35px;
    }

    .fs-hamburger {
        display: flex;
    }

    .fs-drawer-open {
        transform: translateX(0%);
    }

    .fs-close-menu {
        display: flex;
        justify-content: flex-end;
        padding: 5px;
        margin-top: -10px;
        margin-right: -10px;
    }

    .fs-close-menu svg {
        color: var(--primary);
    }

    .fs-side-drawer .ant-menu-vertical {
        border: none;
        margin-top: 30px;
    }

    .fs-drawer-overlay {
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .8);
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    .fs-footer-top .fs-logo {
        padding-left: 0;
    }
}

@media(max-width: 767px) {
    .fs-topbar-contact li:first-child {
        margin-right: 30px;
    }

    .ant-layout-header,
    .fs-header .fs-container {
        height: 55px !important;
    }

    .fs-logo .ant-image-img {
        height: 22px;
    }

    .fs-footer-top .fs-logo .ant-image-img {
        height: 20px;
    }

    .fs-footer-start {
        text-align: center;
    }

    .fs-footer-start .fs-my-account-contact-list li,
    .fs-footer-start .fs-social-media {
        justify-content: center;
    }

    .fs-footer-start .fs-social-media {
        margin-bottom: 50px;
    }

    .fs-footer.ant-layout-footer {
        margin-top: 40px;
    }

    .fs-footer-top {
        padding: 40px 0;
    }
}

@media(max-width: 576px) {
    .fs-topbar {
        flex-direction: column;
    }

    .fs-topbar-start {
        width: 100%;
    }

    .fs-topbar-end {
        display: none;
    }

    .fs-topbar-contact {
        justify-content: space-between;
    }

    .fs-logo .ant-image-img {
        height: 15px;
    }

    .fs-auth-menu .ant-avatar {
        width: 30px;
        height: 30px;
    }

    .fs-auth-nav .ant-btn {
        line-height: 38px !important;
    }
}