.fs-my-account-card {
    margin-top: 30px;
    position: relative;

}

.player-wrapper {
    position: relative;
    padding-top: 56.25%;
    /* Player ratio: 100 / (1280 / 720) */
    overflow: hidden;
    border-radius: 12px;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.react-player__preview svg {
    fill: var(--white);
    color: var(--white);
}

.react-player__preview svg path {
    fill: var(--primary);
    color: var(--primary);
}

.fs-video .ant-tag {
    font-size: 14px;
    padding: 9px 20px 7px;
    text-transform: uppercase;
}

.fs-video-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
}


@media(max-width: 767px) {
    .fs-my-account-card {
        margin-top: 20px;
    }
}