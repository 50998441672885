.ant-input-number {
    width: 100% !important;
}

.ant-picker .ant-picker-range {
    width: 100% !important;

}

.weekdays {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    min-height: 60px;
    border: var(--border) 1px solid;
    border-radius: var(--br-12);
    margin-top: 10px;
}