.fs-blog-post {
    margin-bottom: 40px;
}

.fs-blog-post-img {
    position: relative;
}

.fs-blog-post-img .ant-image {
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
}

.fs-blog-post-img .ant-image img {
    width: 100%;
    height: 375px;
    object-fit: cover;
}

.fs-blog-post-img .ant-tag {
    background-color: var(--primary);
    color: var(--white);
    border-radius: 6px;
    position: absolute;
    bottom: 24px;
    left: 24px;
    border: none;
    height: 30px;
    line-height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: uppercase;
}

.fs-blog-post .ant-typography {
    margin-top: 15px;
    line-height: 1.2;
}

.fs-blog-post .ant-typography a {
    color: var(--text);
}

.fs-blog-post .ant-typography a:hover {
    color: var(--primary);
}

.fs-blog-post p {
    line-height: 1.6;
}

@media(min-width: 1440px) {
    .fs-blog-post-img .ant-image img {
        height: 435px;
    }
}

@media(max-width: 991px) {
    .fs-blog-post {
        margin-bottom: 30px;
    }

    .fs-blog-post-img .ant-image img {
        height: 300px;
    }

    .fs-blog-post .ant-typography {
        font-size: 20px;
    }
}

@media(max-width: 576px) {
    .fs-blog-post-img .ant-image img {
        height: 250px;
    }

    .fs-blog-post .ant-typography {
        font-size: 18px;
    }
}